import { Application, AppType, AppState } from '../../../../../../api/models';
import { useEffect, useState, useCallback, useMemo } from 'react';
import AppRegistryClient from '../../../../../../api/AppRegistryClient';
import { ReactTable } from '../../../Shared/ReactTable/ReactTable';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { Loading } from '../../../Shared/Loading';
import { ApplicationView } from '../../../Applications/ApplicationView';

export interface DuplicatesSearchCheckboxTableProps {
    isLoading: boolean;
    data: Application[];
    arrayFillingFunction: (apps: Application[]) => void;
}

interface ConsolidationExpanded extends Application {
    appType?: AppType;
    appState?: AppState;
}

export const DuplicatesSearchCheckboxTable = ({ isLoading, data, arrayFillingFunction }: DuplicatesSearchCheckboxTableProps) => {
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [appTypes, setAppTypes] = useState<AppType[]>([]);
    const [applications, setApplications] = useState<ConsolidationExpanded[]>([]);
    const [selectedRows, setSelectedRows] = useState<Set<Application>>(new Set());

    const getAppTypes = useCallback(async () => {
        try {
            const result = await AppRegistryClient.getAllAppTypes();
            setAppTypes(result);
        } catch {
            setAppTypes([]);
        }
    }, []);

    const loadData = useCallback(async () => {
        await getAppTypes();
        setDataIsLoading(false);
    }, [getAppTypes]);

    const setExtendedProperties = useCallback(
        function (applications: Application[]): ConsolidationExpanded[] {
            return applications.map((application) => {
                const appType = appTypes.find((appType) => appType.id === application.type);
                return {
                    ...application,
                    appType: appType,
                };
            });
        },
        [appTypes]
    );

    useEffect(() => {
        if (dataIsLoading) {
            loadData();
        }
    }, [dataIsLoading, loadData]);

    useEffect(() => {
        if (!isLoading && !dataIsLoading) {
            setApplications(setExtendedProperties(data));
        }
    }, [isLoading, dataIsLoading, setExtendedProperties, data]);

    const getAppByUniqueName = useCallback(
        function (uniqueName: string): Application | undefined {
            if (dataIsLoading) {
                return undefined;
            }
            const app = applications.find((a) => a.uniqueName === uniqueName);
            return app;
        },
        [applications, dataIsLoading]
    );

    const handleCheckboxChange = useCallback(
        function (app: Application) {
            setSelectedRows((prevSelectedRows) => {
                const newSelectedRows = new Set(prevSelectedRows);
                if (newSelectedRows.has(app)) {
                    newSelectedRows.delete(app);
                } else {
                    newSelectedRows.add(app);
                }
                arrayFillingFunction(Array.from(newSelectedRows));
                return newSelectedRows;
            });
        },
        [arrayFillingFunction]
    );

    const columns = useMemo<ColumnDef<ConsolidationExpanded, Application>[]>(
        () => [
            {
                accessorFn: (row) => row,
                id: 'checkbox',
                header: 'Select',
                cell: (info) => <input type="checkbox" checked={selectedRows.has(info.getValue())} onChange={() => handleCheckboxChange(info.getValue())} />,
                meta: {
                    filterVariant: 'none',
                    className: 'col-1',
                },
                enableSorting: false,
            },
            {
                accessorKey: 'uniqueName',
                id: 'uniqueName',
                header: 'Name',
                cell: (info) => {
                    const uniqueName = info.getValue().toString();
                    return <Link to={`/applications/${uniqueName}`}>{uniqueName}</Link>;
                },
                meta: {
                    filterVariant: 'text',
                    className: 'col-3',
                },
            },
            {
                accessorKey: 'displayName',
                id: 'displayName',
                header: 'Display Name',
                cell: (info) => info.getValue(),
                meta: {
                    filterVariant: 'text',
                    className: 'col-2',
                },
            },
            {
                accessorFn: (row) => ((row.appType?.name ?? '') !== '' ? row.appType?.name : ' '),
                id: 'type',
                header: 'Type',
                cell: (info) => info.getValue(),
                meta: {
                    filterVariant: 'select',
                    className: 'col-2',
                },
                filterFn: 'equals',
            },
            {
                accessorFn: (row) => ((row.deliveryTeam?.name ?? '') !== '' ? row.deliveryTeam?.name : ' '),
                id: 'deliveryTeam',
                header: 'Delivery Team',
                cell: (info) => info.getValue(),
                meta: {
                    filterVariant: 'select',
                    className: 'col-2',
                },
                filterFn: 'equals',
            },
            {
                accessorFn: (row) => ((row.system?.displayName ?? '') !== '' ? row.system?.displayName : ' '),
                id: 'system',
                header: 'System',
                cell: (info) => info.getValue(),
                meta: {
                    filterVariant: 'select',
                    className: 'col-2',
                },
                filterFn: 'equals',
            },
            {
                accessorFn: (row) => row,
                id: 'actions',
                header: 'Actions',
                cell: (info) => {
                    const app = info.getValue();
                    const appExpanded = getAppByUniqueName(app.uniqueName);
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ApplicationView application={appExpanded} />
                        </div>
                    );
                },
                meta: {
                    filterVariant: 'none',
                    className: 'col-1',
                },
                enableSorting: false,
            },
        ],
        [getAppByUniqueName, handleCheckboxChange, selectedRows]
    );

    const initialSorting = [{ id: 'uniqueName', desc: false }] as SortingState;

    if (isLoading || dataIsLoading) {
        return <Loading />;
    }
    return <ReactTable columns={columns} initialSorting={initialSorting} tableData={applications} usePagination={true} />;
};
