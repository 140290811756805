import { useState, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Loading } from '../Shared/Loading';
import { GroupMember } from '../../../../api/models';
import { Filter } from '../Shared/Filter';
import { GroupMembersTable } from './GroupMembersTable';

export interface GroupMembersEnhancedTableProps {
    data: GroupMember[];
    isLoading: boolean;
}

export function GroupMembersEnhancedTable({ data, isLoading }: GroupMembersEnhancedTableProps) {
    const [searchTerm, setSearchTerm] = useState('');

    const searchedData = useMemo(() => {
        const searchString = searchTerm.trim().toLowerCase();

        if (searchString.length === 0) {
            return data;
        }

        return data.filter((groupmember: GroupMember) => {
            return groupmember.displayName.toLowerCase().includes(searchString);
        });
    }, [searchTerm, data]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div>
            <Row>
                <Col>{<Filter onUpdate={setSearchTerm} />}</Col>
            </Row>
            {<GroupMembersTable data={searchedData} />}
        </div>
    );
}
