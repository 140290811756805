import { forwardRef, useImperativeHandle, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { ApplicationView } from '../../../Applications/ApplicationView';
import { ConsolidationWizardModel } from '../../ConsolidationWizard';
import Icon from '@mdi/react';
import { mdiAlertOctagon, mdiChartTimelineVariant, mdiTrashCan } from '@mdi/js';
import { ConsolidationDependencies } from '../../Shared/ConsolidationDependencies';
import { ApplicationAttributesWrapper } from '../../../Applications/Attributes/ApplicationAttributesWrapper';
import { ConsolidationTable } from '../../Shared/ConsolidationTable';
import { Checkbox } from '../../../Shared/Checkbox';
import { ConsolidationConfirmationRow } from './ConsolidationConfirmationRow';
import { ConsolidationEndpointsTable } from './ConsolidationEndpointsTable';
import { Accordion } from 'rsuite';
import AppRegistryClient from '../../../../../../api/AppRegistryClient';
import { useNavigate } from 'react-router';
import { AppRegistrationsEnhancedTable } from '../../../AppRegistrations/AppRegistrationsEnhancedTable';

interface ReviewProps {
    formModel: ConsolidationWizardModel;
    setFormModel: React.Dispatch<React.SetStateAction<ConsolidationWizardModel>>;
}

export const Review = forwardRef<(() => Promise<boolean>) | undefined, ReviewProps>(({ formModel, setFormModel }: ReviewProps, ref) => {
    const [warn1Checked, setWarn1Checked] = useState(false);
    const [warn2Checked, setWarn2Checked] = useState(false);
    const [warn3Checked, setWarn3Checked] = useState(false);
    const warnIconSize = 1.5;
    const navigate = useNavigate();

    useImperativeHandle(ref, () => validate);
    const validate = async () => {
        return warn1Checked && warn2Checked && warn3Checked && (await consolidate());
    };

    const consolidate = async () => {
        const consolidateRequest = structuredClone(formModel.consolidateRequest);
        consolidateRequest.whatIf = false;
        const response = await AppRegistryClient.applications.consolidation(consolidateRequest);
        if (response.errors === undefined || response.errors.length === 0) {
            const url = `/applications/${response.combinedPrimaryApp.uniqueName}`;
            navigate(url);
        }

        return false;
    };

    const primaryApp = formModel.primaryApplication;
    const combinedPrimaryApp = formModel.consolidateResponse.combinedPrimaryApp;

    const primaryAppType = formModel.appTypes.find((at) => at.id === primaryApp.type)?.name;
    const primaryDeliveryTeam = formModel.deliveryTeams.find((dt) => dt.id === primaryApp.deliveryTeamId)?.name;
    const primarySystem = formModel.systems.find((s) => s.id === primaryApp.systemId)?.displayName;

    const combinedAppType = formModel.appTypes.find((at) => at.id === combinedPrimaryApp.type)?.name;
    const combinedDeliveryTeam = formModel.deliveryTeams.find((dt) => dt.id === combinedPrimaryApp.deliveryTeamId)?.name;
    const combinedSystem = formModel.systems.find((s) => s.id === combinedPrimaryApp.systemId)?.displayName;

    const warn1Color = warn1Checked ? 'black' : 'red';
    const warn2Color = warn2Checked ? 'black' : 'red';
    const warn3Color = warn3Checked ? 'black' : 'red';
    return (
        <>
            <Card>
                <Card.Header>
                    Review Consolidation
                    <ApplicationView application={primaryApp} />
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col className="col-2"></Col>
                        <Col className="col-5">Currently on Root</Col>
                        <Col className="col-5">To be set on Root</Col>
                    </Row>
                    <ConsolidationConfirmationRow label={'DisplayName'} oldValue={primaryApp.displayName} newValue={combinedPrimaryApp.displayName} />
                    <ConsolidationConfirmationRow label={'Description'} oldValue={primaryApp.description} newValue={combinedPrimaryApp.description} />
                    <ConsolidationConfirmationRow label={'Type'} oldValue={primaryAppType} newValue={combinedAppType} />
                    <ConsolidationConfirmationRow label={'Delivery Team'} oldValue={primaryDeliveryTeam} newValue={combinedDeliveryTeam} />
                    <ConsolidationConfirmationRow label={'System'} oldValue={primarySystem} newValue={combinedSystem} />
                </Card.Body>
                <Card.Footer>
                    <ConsolidationDependencies application={primaryApp} />
                </Card.Footer>
            </Card>
            <br />
            <Card>
                <Accordion>
                    <Accordion.Panel header="Attributes to be merged" defaultExpanded>
                        <ApplicationAttributesWrapper application={combinedPrimaryApp} canEdit={false} />
                    </Accordion.Panel>
                </Accordion>
            </Card>
            <br />
            <Card>
                <Accordion>
                    <Accordion.Panel header="Endpoints to be merged" defaultExpanded>
                        <ConsolidationEndpointsTable app={combinedPrimaryApp} />
                    </Accordion.Panel>
                </Accordion>
            </Card>
            <br />
            <Card>
                <Accordion>
                    <Accordion.Panel header="App Registrations to be merged" defaultExpanded>
                        <AppRegistrationsEnhancedTable
                            data={combinedPrimaryApp.appRegistrations}
                            isLoading={false}
                            canPerformActions={false}
                            hideSearch={true}
                            hideActions={true}
                        />
                    </Accordion.Panel>
                </Accordion>
            </Card>
            <br />
            <Card>
                <Card.Header>Candidates</Card.Header>
                <Card.Body>
                    <ConsolidationTable data={formModel.duplicateApplications!} isLoading={false} />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body className="bg-warning">
                    <Row>
                        <Checkbox key="warn1" checked={warn1Checked} onChange={(check: boolean) => setWarn1Checked(check)}>
                            <Icon path={mdiAlertOctagon} size={warnIconSize} color={warn1Color} />
                            <span style={{ paddingLeft: '5px', color: warn1Color }}>
                                This step is <b>irreversible</b>.
                            </span>
                        </Checkbox>
                    </Row>
                    <Row>
                        <Checkbox key="warn2" checked={warn2Checked} onChange={(check: boolean) => setWarn2Checked(check)}>
                            <Icon path={mdiTrashCan} size={warnIconSize} color={warn2Color} />
                            <span style={{ paddingLeft: '5px', color: warn2Color }}>
                                This step will <b>delete</b> candidates as apps.
                            </span>
                        </Checkbox>
                    </Row>
                    <Row>
                        <Checkbox key="warn3" checked={warn3Checked} onChange={(check: boolean) => setWarn3Checked(check)}>
                            <Icon path={mdiChartTimelineVariant} size={warnIconSize} color={warn3Color} />
                            <span style={{ paddingLeft: '5px', color: warn3Color }}>
                                This step will <b>break</b> Azure pipeline integration with App Regitry API <b>if</b> any candidate uniqueName was used.
                            </span>
                        </Checkbox>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
});
