import { Button, CloseButton, Modal } from 'react-bootstrap';
import { Application } from '../../../../api/models';
import { ActionButton } from '../UIExtensions/Buttons';
import { mdiMagnifyScan } from '@mdi/js';
import { useState } from 'react';
import { ApplicationDetailsInner } from './ApplicationDetailsInner';

export interface ApplicationViewProps {
    application: Application | undefined;
}

export const ApplicationView = ({ application }: ApplicationViewProps) => {
    const [show, setShow] = useState(false);

    const disabled = application === undefined;
    const buttonTitle = `View ${application?.displayName} application`;
    const onClose = () => {
        setShow(false);
    };
    if (!show) {
        return (
            <ActionButton
                disabled={disabled}
                title={buttonTitle}
                outlined={false}
                size={1}
                mdiIcon={mdiMagnifyScan}
                variant={'btn-dark-blue container-fluid'}
                onClick={async () => {
                    setShow(true);
                }}
            />
        );
    }
    return (
        <>
            <Modal size="xl" centered show={show} onHide={() => onClose()}>
                <Modal.Header>
                    <Modal.Title>Application {application?.displayName}</Modal.Title>
                    <CloseButton onClick={() => onClose()} />
                </Modal.Header>
                <Modal.Body>
                    <ApplicationDetailsInner app={application} isLoading={false} isReadOnly={true} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => onClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
