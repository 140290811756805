import { Col, Row } from 'react-bootstrap';
import {
    AppRegistrationType,
    AppRegistration,
    AppRegistrationExpanded,
    CreateAppRegistrationRequest,
    EnvironmentType,
    AppRegistrationResponse,
    LinkAppRegistrationRequest,
} from '../../../../api/models';
import { Loading } from '../Shared/Loading';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Filter } from '../Shared/Filter';
import { AppRegistrationsTable } from './AppRegistrationsTable';
import _ from 'lodash';
import { tryCallApiCatchErrors } from '../../../../api/Helpers';
import AppRegistryClient from '../../../../api/AppRegistryClient';
import { AppRegistrationCreate } from './AppRegistrationCreate';
import { AppRegistrationStepResults } from './AppRegistrationStepResults';
import { CodeTypeUtils } from '../Shared/CodeTypeUtils';

export interface AppRegistrationsEnhancedTableProps {
    isLoading: boolean;
    data?: AppRegistration[];
    applicationName?: string;
    applicationId?: string;
    canPerformActions: boolean;
    hideActions?: boolean;
    hideSearch?: boolean;
}

export const AppRegistrationsEnhancedTable = ({
    isLoading,
    data,
    applicationName,
    applicationId,
    canPerformActions,
    hideActions,
    hideSearch,
}: AppRegistrationsEnhancedTableProps) => {
    const [applicationRegistrations, setApplicationRegistrations] = useState<AppRegistrationExpanded[]>([]);
    const [state, setState] = useState({ search: '' });
    const [appRegistrationTypes, setAppRegistrationTypes] = useState<AppRegistrationType[]>([]);
    const [environmentTypes, setEnvironmentTypes] = useState<EnvironmentType[]>([]);
    const [stepResults, setStepResults] = useState<AppRegistrationResponse>();
    const [showResults, setShowResults] = useState(false);

    const findExistingAppRegistration = (appRegistration: AppRegistration): AppRegistration | undefined => {
        return _.find(applicationRegistrations, (appReg: AppRegistration) => appReg.id.toLowerCase() === appRegistration.id.toLowerCase());
    };

    const deleteAppRegistration = async (appRegistration: AppRegistration): Promise<Map<string, string[]> | undefined> => {
        return await deleteInternalAppRegistration(appRegistration, async (applicationId: string, id: string) => {
            return await AppRegistryClient.appRegistrations.delete(applicationId, id);
        });
    };

    const unlinkAppRegistration = async (appRegistration: AppRegistration): Promise<Map<string, string[]> | undefined> => {
        return await deleteInternalAppRegistration(appRegistration, async (applicationId: string, id: string) => {
            return await AppRegistryClient.appRegistrations.unlink(applicationId, id);
        });
    };

    const deleteInternalAppRegistration = async (
        appRegistration: AppRegistration,
        deleteAction: (applicationId: string, id: string) => Promise<AppRegistrationResponse>
    ): Promise<Map<string, string[]> | undefined> => {
        const existingAppRegistration = findExistingAppRegistration(appRegistration);
        if (!existingAppRegistration) {
            return undefined;
        }

        const postDeleteCallback = (response: AppRegistrationResponse | undefined) => {
            if (response) {
                response.appRegistration = existingAppRegistration;
            }
            onAppRegistrationDeleted(response);
        };
        return await tryCallApiCatchErrors(async () => deleteAction(existingAppRegistration.applicationId, existingAppRegistration.id), postDeleteCallback);
    };

    const addNewAppRegistration = (appRegistration: AppRegistration) => {
        const existingAppRegistration = findExistingAppRegistration(appRegistration);
        if (existingAppRegistration === undefined) {
        }
        if (existingAppRegistration) {
            return;
        }

        const appRegistrationsCopy = [...applicationRegistrations];
        const appRegistrationType = CodeTypeUtils.findCodeType(appRegistrationTypes, appRegistration.type);
        const environmentType = CodeTypeUtils.findCodeType(environmentTypes, appRegistration.environment);
        const expanded = {
            ...appRegistration,
            appRegistrationType: appRegistrationType,
            environmentType: environmentType,
        } as AppRegistrationExpanded;

        appRegistrationsCopy.push(expanded);

        setApplicationRegistrations(appRegistrationsCopy);
    };

    const updateExistingAppRegistration = (appRegistration: AppRegistration) => {
        const existingAppRegistration = findExistingAppRegistration(appRegistration);
        if (existingAppRegistration) {
            const appRegistrationsCopy = [...applicationRegistrations];
            const index = appRegistrationsCopy.indexOf(existingAppRegistration);
            appRegistrationsCopy.splice(index, 1, appRegistration);
            setApplicationRegistrations(appRegistrationsCopy);
        }
    };

    const removeDeletedAppRegistration = (appRegistration: AppRegistration) => {
        const existingAppRegistration = findExistingAppRegistration(appRegistration);
        if (existingAppRegistration) {
            const appRegistrationsCopy = [...applicationRegistrations];
            appRegistrationsCopy.splice(appRegistrationsCopy.indexOf(existingAppRegistration), 1);
            setApplicationRegistrations(appRegistrationsCopy);
        }
    };

    const createAppRegistration = async (environment: string, applicationName: string): Promise<Map<string, string[]> | undefined> => {
        const request = {
            environment: environment,
            displayName: applicationName,
        } as CreateAppRegistrationRequest;

        return await tryCallApiCatchErrors(async () => await AppRegistryClient.appRegistrations.post(applicationId!, request), onAppResigtrationCreated);
    };

    const linkAppRegistration = async (clientId: string, environment: string, objectId: string): Promise<Map<string, string[]> | undefined> => {
        const request = {
            environment: environment,
            objectId: objectId,
        } as LinkAppRegistrationRequest;

        return await tryCallApiCatchErrors(async () => await AppRegistryClient.appRegistrations.link(applicationId!, clientId, request), onAppResigtrationCreated);
    };

    const onAppRegistrationDeleted = (response: AppRegistrationResponse | undefined) => {
        if (response?.failed === false) {
            removeDeletedAppRegistration(response.appRegistration);
        }
        onResponse(response);
    };

    const onAppResigtrationCreated = (response: AppRegistrationResponse | undefined) => {
        if (response?.appRegistration) {
            addNewAppRegistration(response.appRegistration);
        }
        onResponse(response);
    };

    const onAppResigtrationUpdated = (response: AppRegistrationResponse | undefined) => {
        if (response?.appRegistration) {
            updateExistingAppRegistration(response.appRegistration);
        }
        onResponse(response);
    };

    const onResponse = async (response: AppRegistrationResponse | undefined): Promise<void> => {
        setStepResults(response);
        setShowResults(true);
    };

    const clearStepResults = () => {
        setStepResults(undefined);
        setShowResults(false);
    };

    const handleChange = async (searchValue: string) => {
        setState({ search: searchValue });
    };

    const searchedData = useMemo(() => {
        const searchString = state.search.trim().toLowerCase();
        if (searchString.length > 0) {
            return applicationRegistrations.filter(
                (e) =>
                    e.displayName.toLowerCase().includes(searchString) ||
                    e.clientId.toLocaleLowerCase().includes(searchString) ||
                    e.providerUniqueId.toLocaleLowerCase().includes(searchString) ||
                    e.appRegistrationType?.name.toLocaleLowerCase().includes(searchString) ||
                    e.environmentType?.name.toLocaleLowerCase().includes(searchString)
            );
        }
        return applicationRegistrations;
    }, [state.search, applicationRegistrations]);

    const setAppRegistrations = useCallback(
        async (applicationRegistrations: AppRegistration[]) => {
            const appRegistrations = applicationRegistrations.map((appRegistration) => {
                const appRegistrationType = CodeTypeUtils.findCodeType(appRegistrationTypes, appRegistration.type);
                const environmentType = CodeTypeUtils.findCodeType(environmentTypes, appRegistration.environment);
                return {
                    ...appRegistration,
                    appRegistrationType: appRegistrationType,
                    environmentType: environmentType,
                } as AppRegistrationExpanded;
            });

            setApplicationRegistrations(appRegistrations);
        },
        [appRegistrationTypes, environmentTypes]
    );

    const setTypes = useCallback(async () => {
        const appRegistrationTypesTemp = await appRegistryClient.appRegistrationTypes.getAll();
        setAppRegistrationTypes(appRegistrationTypesTemp);
        const environmentTypesTemp = await appRegistryClient.environmentTypes.getAll();
        setEnvironmentTypes(environmentTypesTemp);
    }, []);

    useEffect(() => {
        setTypes();
    }, [setTypes]);

    useEffect(() => {
        if (data) {
            setAppRegistrations(data);
        }
    }, [data, setAppRegistrations]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div>
            <Row>
                {!hideSearch ? (
                    <Col>
                        <Filter onUpdate={handleChange} />
                    </Col>
                ) : (
                    <></>
                )}
                {!hideActions ? (
                    <Col className="col-1">
                        <AppRegistrationCreate
                            applicationName={applicationName ?? ''}
                            environmentTypes={environmentTypes}
                            disabled={!canPerformActions}
                            onCreate={createAppRegistration}
                            onLink={linkAppRegistration}
                        />
                    </Col>
                ) : (
                    <></>
                )}
            </Row>
            <AppRegistrationsTable
                data={searchedData}
                isLoading={isLoading}
                hideActions={hideActions}
                canPerformActions={canPerformActions}
                onDelete={deleteAppRegistration}
                onUnlink={unlinkAppRegistration}
                environmentTypes={environmentTypes}
                appRegistrationTypes={appRegistrationTypes}
                onConfigurationAssgigned={onAppResigtrationUpdated}
            />
            <AppRegistrationStepResults results={stepResults} show={showResults} onClose={clearStepResults} />
        </div>
    );
};
