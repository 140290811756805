import { Table } from 'react-bootstrap';
import { AppRegistrationExpanded, EnvironmentType, AppRegistrationType, AppRegistrationResponse } from '../../../../api/models';
import { AppRegistrationsTableRow } from './AppRegistrationsTableRow';
import { Loading } from '../Shared/Loading';

export interface AppRegistrationsTableProps {
    isLoading: boolean;
    data?: AppRegistrationExpanded[];
    canPerformActions: boolean;
    hideActions?: boolean;
    onDelete: (appRegistration: AppRegistrationExpanded) => Promise<Map<string, string[]> | undefined>;
    onUnlink: (appRegistration: AppRegistrationExpanded) => Promise<Map<string, string[]> | undefined>;
    environmentTypes: EnvironmentType[];
    appRegistrationTypes: AppRegistrationType[];
    onConfigurationAssgigned: (response: AppRegistrationResponse | undefined) => void;
}

export const AppRegistrationsTable = ({
    isLoading,
    data,
    canPerformActions,
    hideActions,
    onDelete,
    onUnlink,
    environmentTypes,
    appRegistrationTypes,
    onConfigurationAssgigned,
}: AppRegistrationsTableProps) => {
    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="table-wrapper">
            <Table striped={true} className="table-fixed-header">
                <thead className="thead-white">
                    <tr>
                        <th scope="col" className="col-3">
                            Name
                        </th>
                        <th scope="col" className="col-3">
                            Client Id
                        </th>
                        <th scope="col" className="col-3">
                            Provider Unique Id
                        </th>
                        <th scope="col" className="col-1">
                            Type
                        </th>
                        <th scope="col" className="col-1">
                            Environment
                        </th>

                        {!hideActions ? (
                            <>
                                <th scope="col" className="col-1">
                                    Configuration
                                </th>
                                <th scope="col" className="col-1">
                                    Actions
                                </th>
                            </>
                        ) : (
                            <></>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data?.map(function (d) {
                        return (
                            <AppRegistrationsTableRow
                                key={'app-registration-' + d.id}
                                appRegistration={d}
                                canPerformActions={canPerformActions}
                                hideActions={hideActions}
                                onDelete={onDelete}
                                onUnlink={onUnlink}
                                environmentTypes={environmentTypes}
                                appRegistrationTypes={appRegistrationTypes}
                                onConfigurationAssgigned={onConfigurationAssgigned}
                            />
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};
