import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Application, AppType, ConsolidatePrimaryApplicationRequest, System, Team } from '../../../../../../api/models';
import { Card, Row } from 'react-bootstrap';
import { ValidatableInput } from '../../../Shared/ValidatableInput';
import { ValidatableSelect } from '../../../Shared/ValidatableSelect';
import { ApplicationView } from '../../../Applications/ApplicationView';
import { SelectOption } from '../../../Types/SelectOption';
import AppRegistryClient from '../../../../../../api/AppRegistryClient';
import { Loading } from '../../../Shared/Loading';
import { setField } from '../../../../../../helpers/FieldSetter';
import { nameof } from 'ts-simple-nameof';
import { ConsolidationWizardModel } from '../../ConsolidationWizard';
import _ from 'lodash';

interface BasicInfoEditProps {
    formModel: ConsolidationWizardModel;
    setFormModel: React.Dispatch<React.SetStateAction<ConsolidationWizardModel>>;
    appUniqueName: string;
}

export const BasicInfoEdit = forwardRef<(() => Promise<boolean>) | undefined, BasicInfoEditProps>(({ formModel, setFormModel, appUniqueName }: BasicInfoEditProps, ref) => {
    const [appIsLoading, setAppIsLoading] = useState(true);
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [app, setApp] = useState<Application>();
    const [appTypes, setAppTypes] = useState<AppType[]>(formModel.appTypes);
    const [deliveryTeams, setDeliveryTeams] = useState<Team[]>(formModel.deliveryTeams);
    const [appSystems, setSystems] = useState<System[]>(formModel.systems);

    useImperativeHandle(ref, () => validate);
    const validate = async () => {
        const primaryApp = formModel?.consolidateRequest?.primaryApp;
        return primaryApp?.type != null && primaryApp?.systemId != null && primaryApp?.deliveryTeamId != null;
    };

    const getApp = useCallback(async (appUniqueName: string) => {
        const appResult = await AppRegistryClient.applications.get({
            filter: `uniqueName eq '${appUniqueName}'`,
            top: 1,
            expand: `deliveryTeam($select=name),appRegistrations,attributes,system($select=displayName),deployments,sources`,
        });
        if (appResult && appResult.length !== 0) {
            const application = appResult[0];
            setApp(application);
        }
    }, []);

    const getAppTypes = useCallback(async () => {
        if (appTypes.length === 0) {
            try {
                const result = await AppRegistryClient.getAllAppTypes();
                setAppTypes(result);
            } catch {
                setAppTypes([]);
            }
        }
    }, [appTypes.length]);

    const getDeliveryTeams = useCallback(async () => {
        if (deliveryTeams.length === 0) {
            try {
                const result = await AppRegistryClient.getAllTeams();
                setDeliveryTeams(result);
            } catch {
                setDeliveryTeams([]);
            }
        }
    }, [deliveryTeams.length]);

    const getSystems = useCallback(async () => {
        if (appSystems.length === 0) {
            try {
                const result = await AppRegistryClient.getAllSystems();
                setSystems(result);
            } catch {
                setSystems([]);
            }
        }
    }, [appSystems.length]);

    const loadData = useCallback(async () => {
        try {
            await getAppTypes();
            await getDeliveryTeams();
            await getSystems();
        } finally {
            setDataIsLoading(false);
        }
    }, [getAppTypes, getDeliveryTeams, getSystems]);

    const loadApp = useCallback(async () => {
        try {
            await getApp(appUniqueName);
        } finally {
            setAppIsLoading(false);
        }
    }, [getApp, appUniqueName]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        if (!dataIsLoading) {
            loadApp();
        }
    }, [dataIsLoading, loadApp]);

    useEffect(() => {
        if (_.isEmpty(formModel.consolidateRequest.primaryApp) && app !== undefined) {
            const updatedModel = formModel;
            const primaryApp = updatedModel.consolidateRequest.primaryApp;
            primaryApp.id = app.id;
            primaryApp.displayName = app.displayName;
            primaryApp.description = app.description;
            primaryApp.type = app.type;
            primaryApp.systemId = app.systemId!;
            primaryApp.deliveryTeamId = app.deliveryTeamId!;
            formModel.primaryApplication = app;
            setFormModel(updatedModel);
        }
    }, [app, formModel, setFormModel]);

    useEffect(() => {
        if (formModel.appTypes.length === 0) {
            const updatedModel = formModel;
            updatedModel.appTypes = appTypes;
            setFormModel(updatedModel);
        }
    }, [appTypes, formModel, setFormModel]);

    useEffect(() => {
        if (formModel.deliveryTeams.length === 0) {
            const updatedModel = formModel;
            updatedModel.deliveryTeams = deliveryTeams;
            setFormModel(updatedModel);
        }
    }, [deliveryTeams, formModel, setFormModel]);

    useEffect(() => {
        if (formModel.systems.length === 0) {
            const updatedModel = formModel;
            updatedModel.systems = appSystems;
            setFormModel(updatedModel);
        }
    }, [appSystems, formModel, setFormModel]);

    const onFieldChanged = function (fieldName: string, value: any) {
        const updatedModel = structuredClone(formModel);
        if (setField(updatedModel.consolidateRequest.primaryApp, fieldName, value)) {
            setFormModel(updatedModel);
        }
    };

    const displayNameField = nameof<ConsolidatePrimaryApplicationRequest>((primaryApp) => primaryApp.displayName);
    const descriptionField = nameof<ConsolidatePrimaryApplicationRequest>((primaryApp) => primaryApp.description);
    const typeField = nameof<ConsolidatePrimaryApplicationRequest>((primaryApp) => primaryApp.type);
    const deliveryTeamIdField = nameof<ConsolidatePrimaryApplicationRequest>((primaryApp) => primaryApp.deliveryTeamId);
    const systemIdField = nameof<ConsolidatePrimaryApplicationRequest>((primaryApp) => primaryApp.systemId);

    if (dataIsLoading || appIsLoading) {
        return <Loading />;
    }

    const primaryApp = formModel.consolidateRequest.primaryApp;
    return (
        <Card>
            <Card.Header>
                Basic Info: <b>{app?.displayName}</b> <ApplicationView application={app} />
            </Card.Header>
            <Card.Body>
                <Row>
                    <ValidatableInput
                        fieldName={'Display name'}
                        value={primaryApp.displayName}
                        disabled={false}
                        onChange={(newValue: string) => {
                            onFieldChanged(displayNameField, newValue);
                        }}
                    />
                    <ValidatableInput
                        fieldName={'Description:'}
                        value={primaryApp.description}
                        disabled={false}
                        onChange={(newValue: string) => {
                            onFieldChanged(descriptionField, newValue);
                        }}
                    />
                    <ValidatableSelect
                        disabled={false}
                        label={'Type:'}
                        options={appTypes.map((t) => new SelectOption<string>(t.name, t.id))}
                        selectedValue={primaryApp.type}
                        onChange={(newValue: string | undefined) => {
                            if (newValue !== undefined) {
                                onFieldChanged(typeField, newValue);
                            }
                        }}
                        validate={(newValue: string | undefined) => {
                            let errors = [];
                            if (!newValue) {
                                errors.push('Type must be selected');
                            }
                            return errors;
                        }}
                    />
                    <ValidatableSelect
                        disabled={false}
                        label={'Delivery Team:'}
                        options={deliveryTeams.map((t) => new SelectOption<string>(t.name, t.id!))}
                        selectedValue={primaryApp.deliveryTeamId}
                        onChange={(newValue: string | undefined) => {
                            if (newValue !== undefined) {
                                onFieldChanged(deliveryTeamIdField, newValue);
                            }
                        }}
                        validate={(newValue: string | undefined) => {
                            let errors = [];
                            if (!newValue) {
                                errors.push('Team must be selected');
                            }
                            return errors;
                        }}
                    />
                    <ValidatableSelect
                        disabled={false}
                        label={'System:'}
                        options={appSystems.map((s) => new SelectOption<string>(s.displayName, s.id))}
                        selectedValue={primaryApp.systemId}
                        onChange={(newValue: string | undefined) => {
                            if (newValue !== undefined) {
                                onFieldChanged(systemIdField, newValue);
                            }
                        }}
                        validate={(newValue: string | undefined) => {
                            let errors = [];
                            if (!newValue) {
                                errors.push('System must be selected');
                            }
                            return errors;
                        }}
                    />
                </Row>
            </Card.Body>
        </Card>
    );
});
