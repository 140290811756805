import { Table } from 'react-bootstrap';
import { EmptyDataRow } from '../UIExtensions/TableUtilities';
import { Permission } from '../../../../api/models';
import PermissionsDelete from './PermissionsDelete';
import { GroupMembers } from '../GroupMembers/GroupMembers';

export interface PermissionsTableProps {
    id: string;
    data: Permission[];
    availableRoles?: Array<string>;
    deleteHandler: (id: string, groupId: string, role: string) => Promise<boolean>;
}

const PermissionsTable = ({ id, data, availableRoles, deleteHandler }: PermissionsTableProps) => {
    return (
        <div className="table-wrapper">
            <Table striped={true} className="table-fixed-header">
                <thead className="thead-white">
                    <tr>
                        <th scope="col" className="col-4">
                            Group Name
                        </th>
                        <th scope="col" className="col-3">
                            Group Type
                        </th>
                        <th scope="col" className="col-3">
                            Role
                        </th>
                        <th scope="col" className="col-1">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        data.map(function (permission) {
                            const key = `permission-${permission.id}`;
                            const role = permission.role;
                            const canDelete = availableRoles?.includes(role);
                            return (
                                <tr key={key}>
                                    <td>
                                        <GroupMembers groupDisplayName={permission.group?.displayName} groupId={permission.group?.id}></GroupMembers>
                                    </td>
                                    <td>{permission.group?.type}</td>
                                    <td>{permission.role}</td>
                                    <td>
                                        <PermissionsDelete disabled={!canDelete} id={id} data={permission} deleteHandler={deleteHandler} />
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <EmptyDataRow colSpan={4} />
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default PermissionsTable;
